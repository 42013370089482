export interface APIResponseErrorBody {
  error: {
    code: string;
    message: string;
  };
}

export class ApiError extends Error {
  /** Error class name */
  public name: string;

  /** HTTP Response status (e.g. 400) */
  public status: number;

  /** Error code for computers */
  public code: string;

  /** Error message for humans */
  public message: string;

  constructor(status: number, code: string, message: string = code) {
    super(message);
    this.name = this.constructor.name;
    this.status = status;
    this.code = code;
    this.message = message;
  }

  static fromResponse(res: Response, resBody: unknown): ApiError {
    if (isAPIResponseErrorBody(resBody)) {
      return new ApiError(res.status, resBody.error.code, resBody.error.message);
    }
    if (res.status === 400 && isValidationErrorBody(resBody)) {
      return new ApiError(res.status, 'validation_error', 'Some fields are incorrect.');
    }
    return new ApiError(res.status, 'no_error_code', 'Something went wrong.');
  }
}

function isObject(obj: unknown): obj is object {
  return typeof obj === 'object' && obj !== null;
}

function isAPIResponseErrorBody(body: unknown): body is APIResponseErrorBody {
  return isObject(body) && 'error' in body && isObject(body.error) && 'code' in body.error;
}

function isValidationErrorBody(body: unknown): body is APIResponseErrorBody {
  return isObject(body) && 'name' in body && body.name === 'ZodError';
}
